import {Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef} from "@angular/core";
import {EmployeeInvitationResponseGraphModel} from "../../analytics.interface";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {Options} from "highcharts";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {UserWidget} from "../../widget.interface";
import {BaseGraphComponent} from "../base-graph/base-graph.component";

@Component({
  selector: "app-employee-invitation-response-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeInvitationResponseGraphComponent extends BaseGraphComponent {
  elementId = "employeeInvitationResponseGraph";
  options: Options = {
    credits: {
      enabled: false,
    },
    title: {
      text: "Employee Invitations",
      align: "left",
    },
    chart: {
      styledMode: true,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -40,
          format: `{point.y}`,
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "110%",
        showInLegend: true,
      },
      series: {
        events: {
          legendItemClick: (event) => {
            const chart = event.target.chart;

            const seriesStates = chart.series
              .map((series) => ({
                name: series.name as "Visited" | "Started" | "Completed",
                visible: event.target.name == series.name ? !series.visible : series.visible,
              }))
              .filter((x) => x.visible)
              .map((x) => x.name);

            this.widgetConfig.filters = {
              ...this.widgetConfig.filters,
              invitationStage: seriesStates,
            };

            this.analyticsService.editWidgetFilters.emit(this.widgetConfig);
          },
        },
      },
    },
    legend: {
      labelFormatter: function () {
        return `${this.options.description}`;
      },
    },
    series: [],
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            //"printChart",
            //"separator",
            //"downloadPNG",
            //"downloadJPEG",
            //"downloadPDF",
            //"downloadSVG",
            //"separator",
            "downloadCSV",
            //"downloadXLS",
            //"viewData",
            //"openInCloud",
          ],
        },
      },
      filename: "Employee_Invitations",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date): Observable<void> {
    return this.analyticsService.getEmployeeInvitationResponseData(startDate, endDate).pipe(
      tap((backendData: EmployeeInvitationResponseGraphModel) => {
        const fullLabels = {
          Visited: "Visited - followed the invitation link, but no action taken",
          Started: "Started - logged into LinkedIn and created an Employee",
          Completed: "Completed - configured Employee settings",
        };

        this.options.series = [
          {
            type: "pie",
            name: "Number of Invitations",
            innerSize: "50%",
            data: backendData.GraphModels.map((gm) => {
              return {
                y: gm.Count,
                name: fullLabels[gm.CountType],
                description: gm.CountType,
              };
            }),
          },
        ];
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }
}
