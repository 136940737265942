<div
  data-test="employee-advocacy-top-posts-container"
  class="base-table-widget relative flex flex-col bg-white text-light-base-600 dark:text-dark-base-600 dark:bg-dark-base-50 overflow-hidden border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md box-border min-h-[400px] max-h-[400px] h-[400px]">
  <!-- No Data Available -->
  <div
    *ngIf="!model?.Items?.length && !loadingResults && !loading"
    class="absolute text-[14px] inset-0 flex flex-col items-center justify-center h-[400px] text-light-base-400 dark:text-dark-base-400">
    No data to display
  </div>
  <!-- Loading -->
  <div
    *ngIf="loading"
    class="h-1 top-0 left-0 right-0 absolute overflow-hidden bg-light-brand-100/25 dark:bg-dark-brand-100/25">
    <div
      class="employee-table-loading bg-light-brand-100 dark:bg-dark-brand-100 rounded-md absolute bottom-0 top-0 w-1/2"></div>
  </div>
  <div
    *ngIf="loading"
    class="absolute inset-0 flex flex-col items-center justify-center h-[400px] text-light-base-400 dark:text-dark-base-400">
    loading...
  </div>
  <!-- Paginator -->
  <mat-paginator #thePaginator [pageSize]="pageSize" [hidden]="loading" hidePageSize="true"></mat-paginator>
  <ng-container *ngIf="!loading">
    <div class="flex justify-between mx-3 mt-2">
      <!-- Title -->
      <div class="flex items-center">
        <div class="ml-4 text-sm font-normal text-light-base-600 dark:text-dark-base-400">{{ widgetName }}</div>
        <!-- Loading Data -->
        <div *ngIf="loading || loadingResults" class="ml-2">
          <app-loader class="loading-data"></app-loader>
        </div>
      </div>
      <div class="flex shrink-0">
        <!-- Has Groups -->
        <div class="flex mr-[4.5rem]">
          <app-employee-groups-filter
            [initialSelectedGroups]="singleEmployeeGroupSelected()"
            [allGroups]="true"
            (selectedEmployees)="onFilterSelectedEmployeesGroup($event)"
            [showCounter]="false">
          </app-employee-groups-filter>
        </div>
        <!-- Menu -->
        <app-icon-button
          [matMenuTriggerFor]="menu"
          icon="menu-triple"
          iconSize="20"
          type="inline"
          title="Chart context menu"
          class="btn-menu -mt-1 -mr-1 cursor-not-allowed"></app-icon-button>
        <mat-menu #menu="matMenu" xPosition="before" class="selector-menu analytics-widget">
          <button (click)="exportToCsv()" mat-menu-item>Download CSV</button>
          <button (click)="editWidget()" mat-menu-item>Widget Settings</button>
          <button (click)="removeWidget()" mat-menu-item>Remove Widget</button>
        </mat-menu>
      </div>
    </div>
    <!-- Header -->
    <div
      *ngIf="!loading && model?.Items?.length"
      class="grid grid-cols-[60px_repeat(8,_1fr)] text-light-base-400 dark:text-dark-base-400 font-normal text-xs border-0 border-b border-solid border-light-base-300 dark:border-dark-base-300">
      <div class="inline-flex items-center col-span-1 py-1 pl-3 text-left font-medium uppercase" title="Post Rank">
        Rank
      </div>
      <div
        class="inline-flex items-center col-span-4 py-1 text-left font-medium uppercase tracking-wider"
        title="Post Caption">
        Post
      </div>
      <div
        class="inline-flex items-center col-span-1 py-1 font-medium uppercase tracking-wider justify-center"
        title="Likes">
        Likes
      </div>
      <div
        class="inline-flex items-center col-span-1 py-1 font-medium uppercase tracking-wider justify-center"
        title="Shares">
        Shares
      </div>
      <div
        class="inline-flex items-center col-span-1 py-1 font-medium uppercase tracking-wider justify-center"
        title="Comments">
        Cmnts
      </div>
      <div
        class="inline-flex items-center col-span-1 pr-3 py-1 font-medium uppercase tracking-wider justify-center"
        title="Total Engagements">
        Total
      </div>
    </div>
    <!-- Post List -->
    <div
      *ngFor="let post of model?.Items; let rank = index"
      data-test="employee-advocacy-top-posts-table"
      class="grid grid-cols-[60px_repeat(8,_1fr)] text-xs py-px border-0 border-b border-solid last:border-none border-light-base-300 dark:border-dark-base-300 h-[28px] max-h-[28px] box-border odd:bg-light-base-50 dark:odd:bg-dark-base-0/50 hover:bg-light-base-200 dark:hover:bg-dark-base-0">
      <!-- Rank -->
      <div class="flex items-center col-span-1 pl-3 font-medium text-light-base-500 dark:text-dark-base-500">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0 leading-zero">
            {{ this.pageIndex * 10 + rank + 1 }}
          </div>
        </div>
      </div>
      <!-- Caption -->
      <div class="flex items-center col-span-4 font-medium overflow-hidden">
        <div
          class="inline-block max-w-[99%] font-medium text-light-base-600 dark:text-dark-base-400 whitespace-nowrap overflow-ellipsis overflow-hidden truncate">
          <a
            class="text-light-base-600 dark:text-dark-base-400"
            [href]="post.ActivityPermalink"
            target="_blank"
            [title]="post.PostText">
            {{ post.PostText ?? post.MediaTitle ?? "Media Category: " + post.ShareMediaCategory }}
          </a>
        </div>
      </div>
      <!-- Likes -->
      <div class="flex items-center justify-center col-span-1 font-medium text-light-base-500 dark:text-dark-base-500">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0 leading-zero">
            {{ this.isProd ? post.LikeCount : post.EmployeeLikeCount }}
          </div>
        </div>
      </div>
      <!-- Shares -->
      <div class="flex items-center justify-center col-span-1 font-medium text-light-base-500 dark:text-dark-base-500">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0 leading-zero">
            {{ this.isProd ? post.ShareCount : post.EmployeeShareCount }}
          </div>
        </div>
      </div>
      <!-- Comments -->
      <div class="flex items-center justify-center col-span-1 font-medium text-light-base-500 dark:text-dark-base-500">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0 leading-zero">
            {{ this.isProd ? post.CommentCount : post.EmployeeCommentCount }}
          </div>
        </div>
      </div>
      <!-- Total -->
      <div
        class="flex items-center justify-center col-span-1 pr-3 font-medium text-light-base-500 dark:text-dark-base-500">
        <div class="flex items-center space-x-4">
          <div class="flex-shrink-0 leading-zero">
            {{ this.isProd ? post.TotalCount : post.EmployeeTotalCount }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
