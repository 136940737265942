import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from "@angular/core";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import Highcharts from "highcharts";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {UserWidget} from "../../widget.interface";
import {UntilDestroy} from "@ngneat/until-destroy";

NoDataToDisplay(Highcharts);

@UntilDestroy()
@Component({
  selector: "app-earned-media-value-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EarnedMediaValueGraphComponent extends BaseGraphComponent {
  elementId = "earnedmediavaluegraph";
  private totalValue: number;
  private currencyPrefix = "$";
  private currencyName = "Dollars";
  private currentParams = [];

  public options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      styledMode: true,
    },
    title: {
      text: "Earned Media Value",
      align: "left",
    },
    xAxis: {
      categories: [],
      tickmarkPlacement: "on",
      title: {},
    },
    yAxis: {
      title: {
        text: this.currencyName,
      },
      labels: {
        formatter: function () {
          return this.value.toString();
        },
      },
    },
    tooltip: {
      split: true,
      valuePrefix: this.currencyPrefix,
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
      },
      series: {
        events: {
          legendItemClick: (event) => {
            // Get the visibility status of all series
            const chart = event.target.chart;

            const seriesStates = chart.series
              .map((series) => ({
                name: series.name as "Clicks" | "Impressions",
                visible: event.target.name == series.name ? !series.visible : series.visible,
              }))
              .filter((x) => x.visible)
              .map((x) => x.name);

            this.widgetConfig.filters = {
              ...this.widgetConfig.filters,
              earnedMediaMetric: seriesStates,
            };

            this.analyticsService.editWidgetFilters.emit(this.widgetConfig);
          },
        },
      },
    },
    legend: {
      itemStyle: {
        fontWeight: "normal",
      },
    },
    series: [],
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "contrast",
      },
    },
    lang: {
      noData: "No data to display",
    },
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "Earned_Media_Value",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date, profileIds: string[]): Observable<void> {
    return this.analyticsService.getEarnedMediaValueData(startDate, endDate, profileIds).pipe(
      tap((res) => {
        // console.log("[earned-media-value-graph] updateChartData() pipe continuing");

        this.currencyName = this.getCurrencyName(res.CurrencyCode);
        this.options.yAxis = {
          title: {
            text: this.currencyName,
          },
          labels: {
            formatter: function () {
              return this.value.toString();
            },
          },
        };

        this.currencyPrefix = this.getCurrencyPrefix(res.CurrencyCode);
        this.options.tooltip = {
          split: true,
          valuePrefix: this.currencyPrefix,
        };

        (this.options.xAxis as Highcharts.XAxisOptions).categories = res.DataSeries.map((x1) => x1.ColumnName);

        const seriesValue = [];

        let cumValueOfClicks = 0;
        seriesValue.push({
          name: "Clicks",
          data: res.DataSeries.map((x2) => {
            cumValueOfClicks = cumValueOfClicks + x2.ValueOfClicks;
            return Math.round(cumValueOfClicks * 100) / 100;
          }),
        });

        let cumValueOfImpressions = 0;
        seriesValue.push({
          name: "Impressions",
          data: res.DataSeries.map((x2) => {
            cumValueOfImpressions = cumValueOfImpressions + x2.ValueOfImpressions;
            return Math.round(cumValueOfImpressions * 100) / 100;
          }),
        });

        this.options.series = seriesValue;
        this.totalValue = Math.round((cumValueOfClicks + cumValueOfImpressions) * 100) / 100;
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }

  private getCurrencyName(currencyCode: string) {
    if (currencyCode === "GBP") return "Pounds";
    if (currencyCode === "EUR") return "Euros";
    return "Dollars";
  }

  private getCurrencyPrefix(currencyCode: string) {
    if (currencyCode === "GBP") return "£";
    if (currencyCode === "EUR") return "€";
    return "$";
  }
}
