import {Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef} from "@angular/core";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import * as Highcharts from "highcharts";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import {UntilDestroy} from "@ngneat/until-destroy";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {UserWidget} from "../../widget.interface";

exporting(Highcharts);
exportData(Highcharts);
NoDataToDisplay(Highcharts);

@UntilDestroy()
@Component({
  selector: "app-employee-group-growth-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeGroupGrowthGraphComponent extends BaseGraphComponent {
  elementId = "employeeGroupGrowthGraph";

  public options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      styledMode: true,
    },
    title: {
      text: "Employee Growth by Group",
      align: "left",
    },
    xAxis: {
      categories: [],
      tickmarkPlacement: "on",
      title: {},
    },
    yAxis: {
      title: {
        text: "Employees",
      },
      labels: {
        formatter: function () {
          return this.value.toString();
        },
      },
    },
    tooltip: {
      split: true,
      valueSuffix: " employees",
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
      },
      series: {
        events: {
          legendItemClick: (event) => {
            const chart = event.target.chart;

            const seriesStates = chart.series
              .map((series) => ({
                employeeGroupName: series.name,
                visible: event.target.name == series.name ? !series.visible : series.visible,
              }))
              .filter((x) => x.visible)
              .map((x) => x.employeeGroupName);

            //todo: somehow i need to get the id of the group, setting the name for now
            this.widgetConfig.filters = {
              ...this.widgetConfig.filters,
              employeeGroupIds: seriesStates,
            };

            this.analyticsService.editWidgetFilters.emit(this.widgetConfig);
          },
        },
      },
    },
    legend: {
      itemStyle: {
        fontWeight: "normal",
      },
    },
    series: [],
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "contrast",
      },
    },
    lang: {
      noData: "No data to display",
    },
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            //"printChart",
            //"separator",
            //"downloadPNG",
            //"downloadJPEG",
            //"downloadPDF",
            //"downloadSVG",
            //"separator",
            "downloadCSV",
            //"downloadXLS",
            //"viewData",
            //"openInCloud",
          ],
        },
      },
      filename: "Employee_Group_Growth",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date): Observable<void> {
    return this.analyticsService.getEmployeeGroupsGrowthData(startDate, endDate).pipe(
      tap((data) => {
        (this.options.xAxis as Highcharts.XAxisOptions).categories = data.map((x) => {
          return x.ColumnName;
        });

        const seriesValue = [];

        const dictionaryOfValues = new Map<string, number[]>();

        for (const model of data) {
          for (const graphModel of model.EmployeeGroupsGraphModels) {
            const dictionaryValues = dictionaryOfValues.has(graphModel.GroupName)
              ? dictionaryOfValues.get(graphModel.GroupName)
              : [];
            dictionaryOfValues.set(graphModel.GroupName, [...dictionaryValues, graphModel.EmployeeCount]);
          }
        }

        for (const dictionary of dictionaryOfValues) {
          seriesValue.push({
            name: dictionary[0],
            data: dictionary[1],
          });
        }

        this.options.series = seriesValue;
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }
}
