import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import * as Highcharts from "highcharts";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {UserWidget} from "../../widget.interface";
import {EmployeeGroupEngagementGraphModel} from "../../analytics.interface";
import {EngagementType} from "../../analytics-types.enum";
import {E} from "@angular/cdk/keycodes";

exporting(Highcharts);
exportData(Highcharts);
NoDataToDisplay(Highcharts);

@UntilDestroy()
@Component({
  selector: "app-employee-group-engagement-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeGroupEngagementGraphComponent extends BaseGraphComponent {
  elementId = "employeeGroupEngagementGraph";

  public options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      styledMode: true,
    },
    title: {
      text: "Employee Engagement by Group",
      align: "left",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Engagement",
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || "gray",
        },
      },
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 18,
      floating: true,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        events: {
          legendItemClick: (event) => {
            const chart = event.target.chart;

            const seriesStates = chart.series
              .map((series) => ({
                name: series.name as "Likes" | "Comments" | "Shares",
                visible: event.target.name == series.name ? !series.visible : series.visible,
              }))
              .filter((x) => x.visible)
              .map((x) => {
                if (x.name == "Likes") {
                  return EngagementType.Like;
                }
                if (x.name == "Comments") {
                  return EngagementType.Comment;
                }
                return EngagementType.Share;
              });

            this.widgetConfig.filters = {
              ...this.widgetConfig.filters,
              engagementTypes: seriesStates,
            };

            this.analyticsService.editWidgetFilters.emit(this.widgetConfig);
          },
        },
      },
    },
    series: [],
    noData: {},
    lang: {
      noData: "No data to display",
    },
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            //"printChart",
            //"separator",
            //"downloadPNG",
            //"downloadJPEG",
            //"downloadPDF",
            //"downloadSVG",
            //"separator",
            "downloadCSV",
            //"downloadXLS",
            //"viewData",
            //"openInCloud",
          ],
        },
      },
      filename: "Employee_Engagement_by_Group",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date, profileIds: string[]): Observable<void> {
    return this.analyticsService.getEmployeeGroupsEngagementData(startDate, endDate, profileIds).pipe(
      tap((incomingData) => {
        const data: EmployeeGroupEngagementGraphModel[] = incomingData.filter(
          (x) => this.widgetConfig.showNoGroup || x.GroupName != "No Group",
        );

        (this.options.xAxis as Highcharts.XAxisOptions).categories = data.map((x) => x.GroupName);

        const likesValues = [];
        const commentValues = [];
        const shareValues = [];

        for (const model of data) {
          likesValues.push(model.LikesCount);
          shareValues.push(model.ShareCount);
          commentValues.push(model.CommentCount);
        }

        this.options.series = [
          {
            name: "Likes",
            data: likesValues,
            type: null,
          },
          {
            name: "Comments",
            data: commentValues,
            type: null,
          },
          {
            name: "Shares",
            data: shareValues,
            type: null,
          },
        ];
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }
}
