import {Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef} from "@angular/core";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import drilldown from "highcharts/modules/drilldown";
import Highcharts, {XAxisOptions} from "highcharts";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import {UserShareToEmployeesGraphModel} from "../../analytics.interface";
import {environment} from "@environments/environment";
import {UserWidget} from "../../widget.interface";

drilldown(Highcharts);

@Component({
  selector: "app-user-share-employee-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserShareToEmployeesGraphComponent extends BaseGraphComponent {
  isProd = environment.production;
  elementId = "usersharetoemployeesgraph";

  options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      styledMode: true,
      events: {},
    },
    title: {
      text: "User Shares to Employees",
      align: "left",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Content Sent",
      },
      stackLabels: {
        enabled: true,
      },
      allowDecimals: false,
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 18,
      floating: true,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        events: {
          legendItemClick: (event) => {
            const chart = event.target.chart;

            const seriesStates = chart.series
              .map((series) => ({
                name: series.name as "New Content" | "Existing Content",
                visible: event.target.name == series.name ? !series.visible : series.visible,
              }))
              .filter((x) => x.visible)
              .map((x) => {
                if (x.name == "New Content") {
                  return "Composer";
                }
                return "Streams";
              });

            this.widgetConfig.filters = {
              ...this.widgetConfig.filters,
              contentOrigin: seriesStates,
            };

            this.analyticsService.editWidgetFilters.emit(this.widgetConfig);
          },
        },
      },
    },
    series: [],
    drilldown: {
      series: [],
      breadcrumbs: {
        events: {},
      },
    },
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            //"printChart",
            //"separator",
            //"downloadPNG",
            //"downloadJPEG",
            //"downloadPDF",
            //"downloadSVG",
            //"separator",
            "downloadCSV",
            //"downloadXLS",
            //"viewData",
            //"openInCloud",
          ],
        },
      },
      filename: "User_Shares_to_Employees",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date, profileIds: string[]): Observable<void> {
    return this.analyticsService.getUserShareToEmployeesData(startDate, endDate, profileIds).pipe(
      tap((models) => {
        (this.options.xAxis as XAxisOptions).categories = models.map((x) => x.GroupName);

        const composerData = models.map((x) => {
          return {
            name: x.GroupName,
            y: x.Models.map((x) => x.ComposerShares).reduce((a, b) => a + b),
            drilldown: x.GroupName,
          };
        });

        const streamsData = models.map((x) => {
          return {
            name: x.GroupName,
            y: x.Models.map((x) => x.StreamShares).reduce((a, b) => a + b),
            drilldown: x.GroupName,
          };
        });

        this.options.series = [
          {name: "New Content", data: composerData, type: null},
          {name: "Existing Content", data: streamsData, type: null},
        ];

        // no drill down on prod until we have Users from the backend
        if (!this.isProd) {
          this.options.chart.events.drilldown = (drillEvent) => {
            return this.onDrilldownEvent(drillEvent, models, this.elementId);
          };

          this.options.drilldown.breadcrumbs.events.click = (clickEvnt) =>
            this.onBreadcrumbClicked(clickEvnt, models, this.elementId);
        }
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }

  onBreadcrumbClicked(clickEvent: Event, models: UserShareToEmployeesGraphModel[], containerId: string): void {
    const chart = Highcharts.charts.find((x) => x.container.parentElement.id == containerId);

    chart.xAxis[0].update({categories: models.map((x) => x.GroupName)}, true);
  }

  private onDrilldownEvent(
    drillDownEvent: Highcharts.DrilldownEventObject,
    models: UserShareToEmployeesGraphModel[],
    containerId: string,
  ) {
    const chart = Highcharts.charts.find((x) => x.container.parentElement.id == containerId);

    const group = models.find((x) => x.GroupName == drillDownEvent.point.name);

    chart.xAxis[0].update({categories: group.Models.map((x) => x.UserName)}, true);

    if (!drillDownEvent.seriesOptions) {
      const composerModel = {
        stacking: "normal",
        name: "New Content",
        color: Highcharts.getOptions().colors[0],
        data: [],
      };

      const streamsModel = {
        color: Highcharts.getOptions().colors[1],
        colorIndex: 1,
        stacking: "normal",
        name: "Existing Content",
        data: [],
      };

      for (const employeesShares of group.Models) {
        composerModel.data.push([group.GroupName, employeesShares.ComposerShares]);
        streamsModel.data.push([group.GroupName, employeesShares.StreamShares]);
      }

      chart["addSingleSeriesAsDrilldown"](drillDownEvent.point, composerModel);
      chart["addSingleSeriesAsDrilldown"](drillDownEvent.point, streamsModel);

      chart["applyDrilldown"]();
    }
  }
}
