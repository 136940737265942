import {Injector, NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "./material.module";
import {ActivityComponent} from "@activity/activity.component";
import {ActivityHeaderComponent} from "@activity/activity-header/activity-header.component";
import {FacebookActivityComponent} from "@activity/facebook-activity/facebook-activity.component";
import {TwitterActivityComponent} from "@activity/twitter-activity/twitter-activity.component";
import {LinkPreviewComponent} from "@activity/attachments/link-preview/link-preview.component";
import {ImageAttachmentComponent} from "@activity/attachments/image-attachment/image-attachment.component";
import {LinkifyPipe} from "@activity/activity-header/linkify.pipe";
import {VideoAttachmentComponent} from "@activity/attachments/video-attachment/video-attachment.component";
import {VideoModalComponent} from "@activity/attachments/video-attachment/video-modal/video-modal.component";
import {SafePipe} from "@utils/safe.pipe";
import {MomentModule} from "ngx-moment";
import {InstagramActivityComponent} from "@activity/instagram-activity/instagram-activity.component";
import {CarouselAttachmentComponent} from "@activity/attachments/carousel-attachment/carousel-attachment.component";
import {ActivityFooterComponent} from "@activity/activity-footer/activity-footer.component";
import {ShortNumberPipe} from "@utils/short-number.pipe";
import {UserAvatarComponent} from "@user/user-avatar/user-avatar.component";
import {FacebookReactionsComponent} from "@activity/reactions/facebook-reactions/facebook-reactions.component";
import {FacebookReactionsListComponent} from "@activity/reactions/facebook-reactions-list/facebook-reactions-list.component";
import {CommentInputComponent} from "@activity/comments/comment-input/comment-input.component";
import {FacebookCommentComponent} from "@activity/comments/facebook-comments/facebook-comment/facebook-comment.component";
import {FacebookCommentsComponent} from "@activity/comments/facebook-comments/facebook-comments.component";
import {InstagramCommentsComponent} from "@activity/comments/instagram-comments/instagram-comments.component";
import {InstagramCommentComponent} from "@activity/comments/instagram-comments/instagram-comment/instagram-comment.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {LoaderComponent} from "@utils/loader/loader.component";
import {PromptDialogComponent} from "@utils/prompt-dialog/prompt-dialog.component";
import {ProfileSelectorComponent} from "@activity/profile-selector/profile-selector.component";
import {AutofocusDirective} from "@utils/autofocus.directive";
import {OverlayModule} from "@angular/cdk/overlay";
import {ChannelCardComponent} from "@channel/channel-card/channel-card.component";
import {FileUploadDirective} from "@utils/file-upload/file-upload.directive";
import {FileDropDirective} from "@utils/file-upload/file-drop.directive";
import {CreditCardFormComponent} from "@billing/credit-card-form/credit-card-form.component";
import {NotificationPanelComponent} from "@notifications/notification-panel/notification-panel.component";
import {TimeSelectorComponent} from "@utils/time-selector/time-selector.component";
import {PortalModule} from "@angular/cdk/portal";
import {ComposerComponent} from "@composer/composer.component";
import {ProfilePictureComponent} from "@channel/profile-picture/profile-picture.component";
import {ComposerEditorComponent} from "@composer/composer-editor/composer-editor.component";
import {QuillModule} from "ngx-quill";
import {LinkTrackingComponent} from "@shared/composer/link-tracking/link-tracking.component";
import {ComposerPreviewComponent} from "@composer/composer-preview/composer-preview.component";
import {ButtonComponent} from "@layout/button/button.component";
import {ComposerActionsComponent} from "@composer/composer-actions/composer-actions.component";
import {CharsCounterComponent} from "@composer/composer-editor/chars-counter/chars-counter.component";
import {PreviewFooterComponent} from "@composer/composer-preview/preview-footer/preview-footer.component";
import {PublisherActivityComponent} from "@shared/publisher-activity/publisher-activity.component";
import {PreviewDialogComponent} from "@shared/preview-dialog/preview-dialog.component";
import {PublisherActivityHeaderComponent} from "@shared/publisher-activity/publisher-activity-header/publisher-activity-header.component";
import {PageHeaderComponent} from "@layout/page-header/page-header.component";
import {AddChannelDialogComponent} from "@channel/add-channel-dialog/add-channel-dialog.component";
import {MomentFormatPipe} from "@utils/momentFormat.pipe";
import {HashtagPipe} from "@utils/hashtag.pipe";
import {RetweetReplyComponent} from "./retweet-reply/retweet-reply.component";
import {TagsInputComponent} from "@tags/tags-input/tags-input.component";
import {SyndicationActivityComponent} from "@activity/syndication-activity/syndication-activity.component";
import {SelectTeamUserDialogComponent} from "@user/select-team-user/select-team-user.component";
import {VideoPlayerComponent} from "@activity/attachments/video-attachment/video-player/video-player.component";
import {TimePickerComponent} from "@utils/time-selector/time-picker/time-picker.component";
import {LogoMarkComponent} from "@layout/logo-mark/logo-mark.component";
import {LogoTextComponent} from "@layout/logo-text/logo-text.component";
import {ErrorComponent} from "@layout/alert/alert.component";
import {NavComponent} from "@layout/nav/nav.component";
import {MatRippleModule} from "@angular/material/core";
import {ShareComposerComponent} from "@composer/composer-share/composer-share.component";
import {DismissibleSnackbarComponent} from "@utils/dismissable-snackbar/dismissible-snackbar.component";
import {StrokedButtonComponent} from "@layout/stroked-button/stroked-button.component";
import {IconButtonComponent} from "@layout/icon-button/icon-button.component";
import {CountDownSnackbarComponent} from "@utils/countdown-snackbar/countdown-snackbar.component";
import {StripeModule} from "@utils/stripe/stripe.module";
import {MonthDatePipe} from "@utils/month-date.pipe";
import {TagComponent} from "@tags/tag/tag.component";
import {PublisherActivityFooterComponent} from "./publisher-activity/publisher-activity-footer/publisher-activity-footer.component";
import {TagCircleComponent} from "../pages/settings/tags/tag-circle/tag-circle.component";
import {NgScrollbarModule, NG_SCROLLBAR_OPTIONS} from "ngx-scrollbar";
import {NgScrollbarReachedModule} from "ngx-scrollbar/reached-event";
import {DialogCardComponent} from "@channel/add-channel-dialog/dialog-card/dialog-card.component";
import {BasicButtonComponent} from "@layout/basic-button/basic-button.component";
import {ChannelGaugeComponent} from "@channel/channel-gauge/channel-gauge.component";
import {ValidationMessageComponent} from "@composer/composer-validation/validation-message/validation-message.component";
import {MessageComponent} from "@composer/composer-validation/validation-message/message/message.component";
import {ContainerComponent} from "@layout/container/container.component";
import {TextFilterComponent} from "../pages/publisher/content-filters/text-filter/text-filter.component";
import {ProfileFilterComponent} from "../pages/publisher/content-filters/profile-filter/profile-filter.component";
import {TagsFilterComponent} from "../pages/publisher/content-filters/tags-filter/tags-filter.component";
import {ButtonToggleGroupComponent} from "@layout/button-toggle-group/button-toggle-group.component";
import {ButtonToggleComponent} from "@layout/button-toggle-group/button-toggle/button-toggle.component";
import {SystemErrorNotificationTypeComponent} from "@notifications/notification-panel/notification/notification-type/systemerror-notification-type/systemerror-type.component";
import {PublishEventNotificationTypeComponent} from "@notifications/notification-panel/notification/notification-type/publishevent-notification-type/publishevent-type.component";
import {ComposerEmptyComponent} from "@composer/composer-empty/composer-empty.component";
import {AddZapierComponent} from "@channel/add-zapier/add-zapier.component";
import {ConnectZapierComponent} from "@channel/zapier/connect-zapier/connect-zapier.component";
import {PreviewZapierComponent} from "@channel/zapier/preview-zapier/preview-zapier.component";
import {ConfirmationZapierComponent} from "@channel/zapier/confirmation-zapier/confirmation-zapier.component";
import {ConfigureZapierComponent} from "@channel/zapier/configure-zapier/configure-zapier.component";
import {ProgressPreviewZapierComponent} from "@channel/zapier/progress-preview-zapier/progress-preview-zapier.component";
import {ChannelLimitComponent} from "@subscription/upgrade-subscription/upgrade-dialogs/channel-limit/channel-limit.component";
import {ImageCacheDirective} from "@utils/media-cache/media-cache.directive";
import {LinkedInActivityComponent} from "@activity/linkedin-activity/linkedin-activity.component";
import {ZapierEmbedComponent} from "./integrations/zapier-embed/zapier-embed.component";
import {TimezoneSelectorComponent} from "@utils/timezone/timezone-selector/timezone-selector/timezone-selector.component";
import {LinkedChannelsComponent} from "@channel/linked-channels/linked-channels.component";
import {SelectableChannelsComponent} from "@channel/selectable-channels/selectable-channels.component";
import {PhotoEditorComponent} from "./photo-editor/photo-editor.component";
import {AddHashtagGroupComponent} from "@composer/add-hashtag-group/add-hashtag-group.component";
import {SidenavComponent} from "@layout/sidenav/sidenav.component";
import {SidenavItemComponent} from "@layout/sidenav/sidenav-item/sidenav-item.component";
import {SidenavLabelComponent} from "@layout/sidenav/sidenav-label/sidenav-label.component";
import {SidenavHeaderComponent} from "@layout/sidenav/sidenav-header/sidenav-header.component";
import {HeaderComponent} from "@layout/header/header.component";
import {HeaderRightComponent} from "@layout/header/header-right/header-right.component";
import {HeaderLeftComponent} from "@layout/header/header-left/header-left.component";
import {AlertErrorComponent} from "@layout/alert/alert-error/alert-error.component";
import {AlertInfoComponent} from "@layout/alert/alert-info/alert-info.component";
import {AlertSuccessComponent} from "@layout/alert/alert-success/alert-success.component";
import {PlaceholderComponent} from "@layout/placeholder/placeholder.component";
import {PlaceholderImageComponent} from "@layout/placeholder/placeholder-image/placeholder-image.component";
import {AddOnPlanComponent} from "../pages/settings/plans/addon-plan/addon-plan.component";
import {IcAssignedtomeTypeComponent} from "@notifications/notification-panel/notification/notification-type/ic-assignedtome-type/ic-assignedtome-type.component";
import {IcNewconversationTypeComponent} from "@notifications/notification-panel/notification/notification-type/ic-newconversation-type/ic-newconversation-type.component";
import {SidenavDividerComponent} from "@layout/sidenav/sidenav-divider/sidenav-divider.component";
import {FooterComponent} from "@layout/footer/footer.component";
import {FooterLeftComponent} from "@layout/footer/footer-left/footer-left.component";
import {FooterRightComponent} from "@layout/footer/footer-right/footer-right.component";
import {ShareToEmployeeComponent} from "@composer/share-to-emplyee/share-to-employee.component";
import {ProgressBarComponent} from "@layout/progress-bar/progress-bar.component";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {AttachedLinkComponent} from "@composer/composer-editor/attached-link/attached-link.component";
import {AutocompleteinputComponent} from "@utils/autocompleteinput/autocompleteinput.component";
import {BaseNotificationTypeComponent} from "@notifications/notification-panel/notification/notification-type/base-notification-type/base-notification-type.component";
import {EditMetadataComponent} from "@composer/edit-metadata/edit-metadata.component";
import {DateRangeComponent} from "./date-range/date-range.component";
import {ToPresetNamePipe} from "@utils/to-preset-name.pipe";
import {GenericSnackbarComponent} from "@utils/generic-snackbar/generic-snackbar.component";
import {LinkifyFromTokensPipe} from "@activity/activity-header/linkifyFromTokens.pipe";
import {EmployeeGroupsFilterComponent} from "../pages/settings/employees/employee-groups-filter/employee-groups-filter.component";
import {IChatBox, IntercomChatBox} from "@utils/chat/chatbox.interface";
import {IntercomPageTracker, IPageTracker} from "@utils/chat/IPageTracker";
import {GlobalRoleValidatorDirective} from "@utils/global-role/globalrole-validator.directive";
import {ChannelRoleValidatorDirective} from "@utils/channel-role/channel-role-validator.directive";
import {EmployeeGroupRoleValidatorDirective} from "@utils/employeegroup-role/employee-group-role-validator.directive";
import {IconComponent} from "@layout/icon/icon.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {ComposerChannelSelectorComponent} from "@composer/composer-channel-selector/composer-channel-selector.component";
import {ImagePreviewComponent} from "@activity/attachments/image-preview/image-preview.component";
import {TeamsActivityFooterComponent} from "@activity/teams-activity-footer/teams-activity-footer.component";
import {EditVideoComponent} from "@composer/edit-video/edit-video.component";
import {ComposerCreateHashtagGroupComponent} from "@composer/add-hashtag-group/composer-create-hashtag-group/composer-create-hashtag-group.component";
import {ComposerTeamsShareComponent} from "@composer/composer-teams-share/composer-teams-share.component";
import {SelectUserComponent} from "./user/select-user/select-user.component";

export let injectorInstance: Injector;

const components = [
  LogoMarkComponent,
  LogoTextComponent,
  BaseNotificationTypeComponent,
  ShortNumberPipe,
  SafePipe,
  UserAvatarComponent,
  ActivityComponent,
  LoaderComponent,
  PromptDialogComponent,
  AutofocusDirective,
  ChannelCardComponent,
  FileUploadDirective,
  FileDropDirective,
  CreditCardFormComponent,
  NotificationPanelComponent,
  TimeSelectorComponent,
  ComposerComponent,
  ProfilePictureComponent,
  ButtonComponent,
  StrokedButtonComponent,
  IconButtonComponent,
  PublisherActivityComponent,
  PageHeaderComponent,
  AddChannelDialogComponent,
  MomentFormatPipe,
  HashtagPipe,
  RetweetReplyComponent,
  TagsInputComponent,
  SelectTeamUserDialogComponent,
  RetweetReplyComponent,
  ErrorComponent,
  NavComponent,
  ShareComposerComponent,
  MonthDatePipe,
  TagComponent,
  TagCircleComponent,
  TextFilterComponent,
  ProfileFilterComponent,
  TagsFilterComponent,
  SystemErrorNotificationTypeComponent,
  PublishEventNotificationTypeComponent,
  ComposerEmptyComponent,
  ChannelLimitComponent,
  ConnectZapierComponent,
  AddZapierComponent,
  PreviewZapierComponent,
  ConfirmationZapierComponent,
  ConfigureZapierComponent,
  ProgressPreviewZapierComponent,
  ImageCacheDirective,
  TimezoneSelectorComponent,
  ButtonToggleGroupComponent,
  LinkedChannelsComponent,
  SelectableChannelsComponent,
  PhotoEditorComponent,
  AddOnPlanComponent,
  LinkTrackingComponent,
  AutocompleteinputComponent,
  MessageComponent,
  EditMetadataComponent,
  DateRangeComponent,
  ToPresetNamePipe,
  EmployeeGroupsFilterComponent,
  GlobalRoleValidatorDirective,
  ChannelRoleValidatorDirective,
  EmployeeGroupRoleValidatorDirective,
  TeamsActivityFooterComponent,
  EditVideoComponent,
  ComposerTeamsShareComponent,
  SelectUserComponent,
];

export let InjectorInstance: Injector;

export function setInjector(injector: Injector) {
  InjectorInstance = injector;
}

@NgModule({
  declarations: [
    ActivityHeaderComponent,
    LinkPreviewComponent,
    FacebookActivityComponent,
    TwitterActivityComponent,
    LinkifyPipe,
    LinkifyFromTokensPipe,
    ImageAttachmentComponent,
    VideoAttachmentComponent,
    VideoModalComponent,
    InstagramActivityComponent,
    CarouselAttachmentComponent,
    FacebookReactionsComponent,
    ActivityFooterComponent,
    FacebookCommentsComponent,
    FacebookCommentComponent,
    FacebookReactionsListComponent,
    CommentInputComponent,
    InstagramCommentsComponent,
    InstagramCommentComponent,
    ProfileSelectorComponent,
    ComposerEditorComponent,
    ComposerPreviewComponent,
    ComposerActionsComponent,
    CharsCounterComponent,
    PreviewFooterComponent,
    PreviewDialogComponent,
    PublisherActivityHeaderComponent,
    SyndicationActivityComponent,
    VideoPlayerComponent,
    TimePickerComponent,
    components,
    DismissibleSnackbarComponent,
    GenericSnackbarComponent,
    CountDownSnackbarComponent,
    PublisherActivityFooterComponent,
    DialogCardComponent,
    BasicButtonComponent,
    ChannelGaugeComponent,
    ValidationMessageComponent,
    ContainerComponent,
    ButtonToggleComponent,
    LinkedInActivityComponent,
    ZapierEmbedComponent,
    AddHashtagGroupComponent,
    SidenavComponent,
    SidenavItemComponent,
    SidenavLabelComponent,
    SidenavHeaderComponent,
    HeaderComponent,
    HeaderRightComponent,
    HeaderLeftComponent,
    AlertErrorComponent,
    AlertInfoComponent,
    AlertSuccessComponent,
    PlaceholderComponent,
    PlaceholderImageComponent,
    IcAssignedtomeTypeComponent,
    IcNewconversationTypeComponent,
    SidenavDividerComponent,
    FooterComponent,
    FooterLeftComponent,
    FooterRightComponent,
    ShareToEmployeeComponent,
    ProgressBarComponent,
    AttachedLinkComponent,
    IconComponent,
    ComposerChannelSelectorComponent,
    ImagePreviewComponent,
    ComposerCreateHashtagGroupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    MomentModule,
    ScrollingModule,
    OverlayModule,
    PortalModule,
    QuillModule,
    MatRippleModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    StripeModule,
    ClipboardModule,
    MatPaginatorModule,
    MatTableModule,
    NgOptimizedImage,
  ],
  providers: [
    {provide: IChatBox, useClass: IntercomChatBox},
    {provide: IPageTracker, useClass: IntercomPageTracker},
    {provide: "InjectorInstance", useFactory: setInjector, deps: [Injector]},
    {provide: NG_SCROLLBAR_OPTIONS, useValue: {visibility: "hover"}},
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    components,
    BasicButtonComponent,
    ContainerComponent,
    SidenavComponent,
    SidenavItemComponent,
    SidenavLabelComponent,
    SidenavHeaderComponent,
    HeaderComponent,
    HeaderLeftComponent,
    AlertErrorComponent,
    AlertInfoComponent,
    AlertSuccessComponent,
    PlaceholderComponent,
    HeaderRightComponent,
    PlaceholderImageComponent,
    SidenavDividerComponent,
    FooterComponent,
    FooterLeftComponent,
    FooterRightComponent,
    ProgressBarComponent,
    IconComponent,
  ],
})
export class SharedModule {}
