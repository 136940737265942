import {Component, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Inject, computed} from "@angular/core";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import AbstractPaginatedComponent from "../../abstract-paginated.component";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import moment from "moment";
import {CsvFileInfo, IBaseTableDataModel} from "../../analytics.interface";
import {CsvExporter} from "@shared/analytics/json-to-csv.export";
import {IEmployeeGroup} from "src/app/pages/auth/employee/employee";
import {UserWidget} from "../../widget.interface";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {toSignal} from "@angular/core/rxjs-interop";

@UntilDestroy()
@Component({
  selector: "app-base-table",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseTableComponent<T> extends AbstractPaginatedComponent implements AfterViewInit {
  loading = true;
  loadingResults = false;

  model: IBaseTableDataModel<T>;

  employeeGroupsSelected: BehaviorSubject<IEmployeeGroup[]> = new BehaviorSubject(null);

  employeeGroupsSelectedSignal = toSignal(this.employeeGroupsSelected);

  singleEmployeeGroupSelected = computed(() => {
    if (this.employeeGroupsSelected.value?.length == 1) {
      return this.employeeGroupsSelected.value[0];
    }
    return null;
  });

  abstract fileName: string;

  abstract getCsvHeaders();

  widgetName = "";

  protected constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timerangeListener: TimerangeListenerService,
    protected profilesListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") public widgetConfig: UserWidget,
  ) {
    super();
    this.widgetName = widgetConfig.displayName;

    if (widgetConfig.filters?.employeeGroupIds) {
      this.employeeGroupsSelected.next(
        widgetConfig.filters?.employeeGroupIds.map((x) => ({Id: x, Name: "", Selected: true})),
      );
      console.log("employeeGroupsSelected", this.employeeGroupsSelected.value);
    }
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.timerangeListener.startDate$,
      this.timerangeListener.endDate$,
      this.thePaginator.page,
      this.employeeGroupsSelected,
      this.profilesListener.selectedProfiles$,
    ])
      .pipe(
        untilDestroyed(this),
        debounceTime(1500),
        distinctUntilChanged(),
        map(([startDate, endDate, pageEvent, groupsSelected, selectedProfiles]) => {
          return {
            startDate: moment(startDate).startOf("day").toDate(),
            endDate: moment(endDate).endOf("day").toDate(),
            pageIndex: pageEvent.pageIndex,
            groupsIds: groupsSelected ? groupsSelected.map((x) => x.Id) : null,
            profilesIds: selectedProfiles?.length > 0 ? selectedProfiles.map((x) => x.ExternalId) : null,
          };
        }),
      )
      .subscribe((obj) => {
        this.loadingResults = true;
        this.cdr.markForCheck();
        this.updateTableData(obj.startDate, obj.endDate, obj.pageIndex, obj.groupsIds, obj.profilesIds);
      });

    this.thePaginator.page.emit(this.defaultPageEvent());
  }

  abstract getTableData(
    startDate: Date,
    endDate: Date,
    pageIndex: number,
    pageSize: number,
    groupsIds: string[],
    profilesSelectedIds: string[],
  ): Observable<IBaseTableDataModel<T>>;

  updateTableData(startDate: Date, endDate: Date, pageIndex: number, groupsIds: string[], profilesSelected: string[]) {
    if (this.model) {
      this.resetPaginatorIfDateRangeChanges(
        this.thePaginator,
        startDate,
        this.model.StartTime,
        endDate,
        this.model.EndTime,
      );
    }

    this.getTableData(startDate, endDate, pageIndex, this.pageSize, groupsIds, profilesSelected)
      .pipe(untilDestroyed(this))
      .subscribe((responseModel) => {
        this.model = responseModel;
        this.updatePagination(this.thePaginator, pageIndex, responseModel.HasMoreRows, this.model.Items.length);
        this.loadingResults = false;
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  exportToCsv(): void {
    this.exportToCsvData(true).subscribe();
  }

  editWidget() {
    this.analyticsService.editWidgetEvent.emit(this.widgetConfig);
  }

  removeWidget() {
    this.analyticsService.removeWidgetEvent.emit(this.widgetConfig);
  }

  exportToCsvData(downloadFile: boolean): Observable<CsvFileInfo> {
    const exporter = new CsvExporter();

    if (!this.model.HasMoreRows && this.model.PageNum == 0) {
      return of({
        name: this.fileName,
        data: exporter.exportToCsvFile(this.getCsvHeaders(), this.model.Items, this.fileName, downloadFile),
      });
    } else {
      let groups: IEmployeeGroup[];
      if (this.employeeGroupsSelected && this.employeeGroupsSelected.value) {
        groups = this.employeeGroupsSelected.value;
      }

      const selectedProfiles = this.profilesListener.selectedProfiles$.value;

      return this.getTableData(
        this.timerangeListener.startDate$.value,
        this.timerangeListener.endDate$.value,
        0,
        Math.max(this.exportLimit, (this.thePaginator.pageIndex + 1) * this.pageSize),
        groups ? groups.map((x) => x.Id) : null,
        selectedProfiles ? selectedProfiles.map((x) => x.ExternalId) : null,
      ).pipe(
        untilDestroyed(this),
        map((x) => {
          return {
            name: this.fileName,
            data: exporter.exportToCsvFile(this.getCsvHeaders(), x.Items, this.fileName, downloadFile),
          };
        }),
      );
    }
  }

  onFilterSelectedEmployeesGroup(event: IEmployeeGroup[]) {
    this.thePaginator.pageIndex = 0;
    this.thePaginator.length = 0;
    this.thePaginator.page.emit(this.defaultPageEvent());
    if (event.some((x) => x.Id == null)) {
      this.employeeGroupsSelected.next(null);
    } else this.employeeGroupsSelected.next(event);

    this.widgetConfig.filters = {
      ...this.widgetConfig.filters,
      employeeGroupIds: this.employeeGroupsSelected.value?.map((x) => x.Id),
    };

    this.analyticsService.editWidgetFilters.emit(this.widgetConfig);
  }
}
