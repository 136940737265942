import {ChangeDetectionStrategy, Component, OnInit, forwardRef, signal} from "@angular/core";
import {User} from "../user.interface";
import {TeamService} from "../team.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: "app-select-user",
  templateUrl: "./select-user.component.html",
  styleUrl: "./select-user.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectUserComponent),
      multi: true,
    },
  ],
})
export class SelectUserComponent implements OnInit, ControlValueAccessor {
  selectedUserIds: string[] = [];
  onChange: (value: string[]) => void = () => {};
  onTouched: () => void = () => {};
  users = signal<User[]>([]);

  constructor(private teamService: TeamService) {}

  ngOnInit(): void {
    this.teamService.getAllUsers().subscribe((users) => {
      this.users.set(users);
    });
  }

  writeValue(value: string[]): void {
    this.selectedUserIds = value || [];
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }

  onUserSelectionChange(checked: boolean, selectedUserId: string): void {
    console.log({checked, selectedUserId});
    if (checked) {
      if (selectedUserId) {
        this.selectedUserIds.push(selectedUserId);
      }
    } else {
      this.selectedUserIds = this.selectedUserIds.filter((id) => id !== selectedUserId);
    }
    this.onChange(this.selectedUserIds);
    this.onTouched();
  }

  onSelectionChange(event: MatSelectChange): void {
    this.selectedUserIds = event.value;
    this.onChange(this.selectedUserIds);
  }
}
